

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
    border    : none;
    box-shadow: none;
    z-index   : 1;
}

.sp-input {
    height          : 48px;
    border-radius   : 40px;
    border          : none;
    padding         : 16px 24px;
    background-color: #f9f6ff;

    &:focus, &.ant-input-affix-wrapper-focused  {
        border: 1px solid #7535fd;
        box-shadow: 0 0 0 1px #7535fd !important;
    }

    &__spin {
        color: #7535fd;
    }

    &.sp-outlined {
        border: 2px solid #f9f6ff;
    }
}
