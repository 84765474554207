.sp-message {
    margin-top: 8px;
    font-size : 11px;
    font-weight: 500;

    &.sp-message.success {
        color: #318D1F;
    }

    &.sp-message.danger {
        color: #dc3545;
    }

    &.sp-message.warning {
        color: #ffc107;
    }
    
    &.sp-message.info {
        color: #17a2b8;
    }
}

