.modal_components {
  display: flex;
  flex-direction: column;
  padding-block: 20px;
  &__button {
    // margin-top: 30px;
    &.double_buttons {
      display: flex;
      gap: 16px;

      @media only screen and (max-width: 768px) {
        justify-content: center;
        > button {
          width: 107px !important;
          padding: 0 !important;
          height: 40px !important;
        }
      }
    }
  }
  > div p,
  h3 {
    margin-bottom: 0 !important;
  }
  &__primary-text {
    font-family: $gs-medium;
    font-size: 20px;
    color: $primaryTextColor;
  }
  &__otp-error {
    position: absolute;
    left: 16px;
    top: 50px;
    font-family: $gs-medium;
    font-size: 11px;
    color: $error;
  }
  &__otp-container {
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .date-btn-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > button {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      width: 100%;
      height: 48px;
      background: #f9f6ff;
      border-radius: 26px;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;
      color: #121020;
      outline: none;
      border: none;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        border: 1px solid #7535fd;
      }
      > .absolute-date {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        height: 100%;
        background: #f9f6ff;
        position: absolute;
        left: 0;
        z-index: 100;
        padding: 24px;
      }
      > input {
        width: 90%;
        background: #f9f6ff;
        border: none;
        outline: none;
      }
      > input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
      }
    }
  }
}

.access-check {
  & input {
    font-family: $cg-bold;
    letter-spacing: 0.1em;
    line-height: 35px;
  }
  &__input {
    font-size: 28px;
    height: 55px !important;
    color: #000000;
  }
}

.otp-input {
  & input {
    width: 48px !important;
    height: 48px !important;
    border-radius: 8px;
    background: #f9f6ff;
    border: none !important;

    font-family: $gs-regular;
    font-style: normal;
    font-size: 20px;
    line-height: 144%;
    color: #2c264b;
    &:focus {
      border: 1px solid #7535fd !important;
      outline: none !important;
    }
    &:not([value=""]):not(:focus) {
      background: #7535fd;
    }

    @media (max-width: 500px) {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.transaction-pin {
  padding-inline: 3em;

  &__empty {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__delete {
    cursor: pointer;
  }
}

.pin-secret {
  font-size: 32px;
  letter-spacing: 0.2em;
}

.otp-btns {
  display: flex;
  flex-direction: column;
  width: 41.84%;
  gap: 16px;
  &_transparent {
    font-family: $gs-medium;
    color: $primaryTextColor;
    font-size: 14px;
    display: flex;
    height: 40px;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    border-radius: 26px;
    background: transparent;
  }
}

.success-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.edit-schedule-btn-container {
  width: 160px;
  height: 40px;
  margin: 0 auto;
  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    > span {
      > span {
        display: flex;
        align-items: center;
        gap: 8px;
        > svg {
          > path {
            fill: $primaryTextColor;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $small-screen) {
  .transaction-pin {
    padding-inline: 1em;
  }
}

.filter-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &_label {
    font-family: $gs-regular;
    font-size: 14px;
    color: #000000;
    width: fit-content;
  }
  &_button {
    font-family: $gs-regular;
    font-size: 14px;
    color: $lceeBlack;
    display: flex;
    padding: 0 16px;
    height: 48px;
    max-height: 48px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 26px;
    border: 1px solid $primaryTextColor;
    background: #f9f6ff;
    cursor: pointer;
    svg {
      width: 11px !important;
      height: 11px !important;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
