.sp-summary-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: url('../../assets/svgs/summary-card.svg') no-repeat center center;
    background-size: cover; 
    border-radius: 12px;

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 32px;
        width: 100%;
        height: auto;
    }

    &__left-column
    {
        flex: 1;
    }

    &__left-column {
        margin-right: 16px;
    }

    &__right-column
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__title {
        font-size: 11px;
        font-weight: 400;
        margin: 8px 0px;
        padding: 0;
        color: #898989;
        line-height: 15.84px;
        font-family: "General Sans";
    }
    &__text {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        color: #121020;
        line-height: 25.92px;
        font-family: "General Sans";
    }
}
