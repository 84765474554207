@import "abstracts";
@import "mixins.scss";
@import "vendors";
@import "media.scss";
@import "components";
@import "typography";
@import "custom.scss";
@import "common.scss";
@import "utilities";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


// .hydrated {
//   overflow-x: hidden;
// }
body {
  margin: 0;
  font-family: $gs-regular;
  font-style: normal;
  font-size: 14px;
  // font-size: 0.97vw !important;
  /* line-height: 180%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body.loading {
  overflow: hidden;
  position: relative;
}

body.loading::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff; /* Pure white background */
  z-index: 9998; /* Lower z-index than spinner */
}

body.loading .spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #7535fd; /* Purple for contrast */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  z-index: 9999; /* Ensure spinner is on top */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.title {
  font-family: $cg-bold;
  font-style: normal;
  font-size: 64px;
  line-height: 79px;
  color: #2c264b;
}

.welcome-img {
  width: "100%";
}

.line_break {
  &__sm {
    height: 20px;
  }
  &__lg {
    height: 40px;
  }
}

.main-layout {
  // min-height: 70vh !important;
  padding: 24px;
  border-radius: $borderRadius;
  background: $white;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}

.dev-note {
  cursor: pointer;
  & span {
    font-family: $gs-medium;
  }
}
