.service_tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  // margin-bottom: 15px;
  gap: 10px;
  width: 194px;
  max-width: 194px;
  // height: 150px;
  background: #f4f0fc;
  border-radius: 8px;
  cursor: pointer;

  
  @media(max-width: 500px) {
    padding: 10px;
    width: 182px;
    margin-bottom: 16px;
  }


  @media(max-width: 400px) {
    padding: 10px;
    width: 138px;
    margin-bottom: 16px;
  }

  &:hover {
    animation: zoom-in-zoom-out 2s ease-out;

    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.1, 1.1);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }

  &__text {
    font-family: $gs-medium;
    font-size: 1em;
    line-height: 144%;
    color: #000000;
    flex-grow: 0;

  }

  &__img {
    filter: sepia(1) saturate(10000%) hue-rotate(260deg);
    mix-blend-mode: multiply; 
    width: 32px;
  }

  &__img_wrapper {
    display: inline-block;
    padding: 8px;
   /*  background-color: #ede4ff;
    border: 1px solid #d8c6ff; */
    border-radius: 50%;
  }
}

@media screen and (max-width: 991.98px) {
  .homepage {
    &__container {
      &__row-two {
        display: none !important;
      }
    }
  }

  .service_tile {
    &__text {
      font-size: 1.125em;
    }

    &__wrapper {
      margin-right: 0px !important;
      margin-left: 0px !important;

      > .ant-col {
        padding-right: 0px !important;
        padding-left: 0px !important;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .homepage {
    &__container {
      margin: 0px !important;

      &__row-one {
        padding: 0.7vw !important;
      }
    }
  }

}
