
.sp-transaction-card {
    padding: 24px;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    text-align: center;

    &__row {
        display: flex;
        justify-content: space-between;
        padding: 12px 0px;
        
        &:not(:last-child) {
            border-bottom: 0.5px solid rgba(234, 233, 237, 1);
        }
    }
}