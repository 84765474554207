@import "./variables";

@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../assets/fonts/CabinetGrotesk-Regular.ttf");
}

@font-face {
  font-family: "Cabinet Grotesk Medium";
  src: url("../assets/fonts/CabinetGrotesk-Medium.ttf");
}

@font-face {
  font-family: "Cabinet Grotesk Bold";
  src: url("../assets/fonts/CabinetGrotesk-Bold.ttf");
}

@font-face {
  font-family: "General Sans";
  src: url("../assets/fonts/GeneralSans-Regular.ttf");
}

@font-face {
  font-family: "General Sans Medium";
  src: url("../assets/fonts/GeneralSans-Medium.ttf");
}

@font-face {
  font-family: "General Sans Semi Bold";
  src: url("../assets/fonts/GeneralSans-Semibold.ttf");
}

@font-face {
  font-family: "General Sans Bold";
  src: url("../assets/fonts/GeneralSans-Bold.ttf");
}
