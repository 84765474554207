.sp-steps {
    margin-left: -4px;
    &.ant-steps {
        .ant-steps-item-process, .ant-steps-item-finish  {
            & > .ant-steps-item-container {
                & > .ant-steps-item-content {
                    & > .ant-steps-item-title {
                        color: $primaryTextColor;
                        font-weight: 600;
                        font-size: 11px;

                        &::after {
                            background-color: $primaryTextColor;
                        }
                    }
                }
            }
        }
        .ant-steps-item-wait {
            & > .ant-steps-item-container {
                & > .ant-steps-item-content {
                    & > .ant-steps-item-title {
                        font-weight: 600;
                        font-size: 11px;
                    }
                }
            }
        }
    }

    @media (max-width: 500px) {
        display: flex;
        flex-direction: row !important;
        text-align: center;
        margin-left: 0px;

        & > .ant-steps-item {
            display: block;
            flex: auto !important;
            padding-inline-start: 0;
            overflow: visible;
        }
    }
}

[class^="ant-steps"], [class*=" ant-steps"] {
    font-family: "General Sans";
    font-size: 11px;
}

.sp-steps__content {
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;
}