@mixin hoverZoomInOut() {
  &:hover {
    animation: zoom-in-zoom-out 2s ease-out;
    // border: 1px $primaryTextColor $borderStyle;

    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.1, 1.1);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
}
