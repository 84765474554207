.sp-loading__container {
    position: relative;
    padding: 24px;
}

.sp-loading {
    &__overlay {
        position        : absolute;
        top             : 0;
        right           : 0;
        bottom          : 0;
        left            : 0;
        background-color: rgba(0, 0, 0, 0.01); /* Semi-transparent black */
        border-radius   : 8px;
        display         : flex;
        justify-content : center;
        align-items     : center;
        z-index         : 1000; /* Ensure the overlay is on top */
        color           : white; /* Color for the loading text or spinner */
    }

    &__spinner {
        border       : 3px solid rgba(151, 71, 255, 1);
        border-radius: 50%;
        border-top   : 4px solid white;
        width        : 40px;
        height       : 40px;
        animation    : spin 1s linear infinite;
        margin-top: -20rem;
        &.hide {
            display: none !important;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}


#nprogress .bar {
    background: rgba(151, 71, 255, 1) !important;
  
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 2px;
  }