.homepage {
  background: transparent !important;
  
  // margin-inline: 0px !important;

  &__img {
    width: 90%;
  }
  &__container {
    margin: 0vw 4.86vw;

    &__row-one {
      padding: 25px;
    }
    &__row-two {
      display: flex;
      align-items: flex-end;
    }
  }
  &_content__img_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__title {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 8px;
    text-align: center;
  }
  
  &__inner-container {
    max-width: 650px;
    margin: 0 auto;
  }

  &__biller-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)); /* Ensures columns don't shrink */
    gap: 32px;
    background-color: transparent;
    border-radius: 24px;
    margin: 0 auto;

    @media (max-width: 500px) {
      grid-template-columns: repeat(2, minmax(0, 1fr)); /* Ensures columns don't shrink */
    }
  }
  
}


@media (max-width: 500px) {
  .homepage {
    &__title {
      text-align: left;
    }
  
    &__biller-grid {
      row-gap: 8px;
      border-radius: 24px;
    }
  }
}