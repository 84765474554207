
.sp-wallet-card {
    background: url(../../assets/images/card-bg.png) no-repeat top center;
    background-size: cover; /* Makes the image cover the entire card */
    background-color: #f0f0f0; /* Default background color if the image doesn't load */
    width: 100%; /* Adjust width based on your design */
    height: 100%; /* Adjust height based on your design */
    border-radius: 12px; /* Optional: Add border-radius to the card */
    overflow: hidden; /* Ensures the image doesn’t spill outside the card */
    padding: 16px;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__title {
        color: #fff;
        font-size: 12px;
        line-height: normal;
        font-family: "General Sans";
        opacity: 0.8;
    }

    &__text {
        color: #d2c9e8;
        font-size: 32px;
        line-height: normal;
        font-family: $gs-medium;
        font-weight: 600;
    }

    &__currency {
        color: #d2c9e8;
        font-size: 14px;
        line-height: normal;
        font-family: $gs-medium;
        font-weight: 600;
    }
}