

.layout {
  background: $white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;

  &__kyc-header {
    font-family: $gs-regular;
    font-size: 12px;
    font-weight: 500;
    line-height: 144%;
    color: #af6606 !important;
    display: flex;
    width: 100%;
    min-height: 49px !important;
    height: 49px !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid #fdddb3 !important;
    background: #fef4e6 !important;

    > a {
      font-family: $gs-regular;
      font-size: 12px;
      font-weight: 600;
      line-height: 144%;
      color: $primaryTextColor;
      text-decoration: underline;
    }
  }

  &__header {
    background: #fff !important;
    box-shadow: 0 1px 4px rgba(25,8,61,.1);
    height: auto !important;
    line-height: 50px !important;
    margin-bottom: 1rem;
    padding: 8px;

    @media (max-width: 600px) {
      padding: 12px;
    }
  }

  &__logo {
    width: 120px;
    object-fit: contain;
    cursor: pointer;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 7vw;
    // background: red;

    & > div {
      display: flex;
      align-items: center;
    }

    &_mobile {
      display: none;
    }
  }
  &__login_text {
    margin-right: 32px;
    cursor: pointer;
    height: fit-content !important;
  }
  &__button {
    height: 35px;
    // width: auto;
    padding-inline: 15px !important;
  }
  &__child {
    margin-left: 0px !important;
  }
  &__need_help {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    color: $primaryTextColor;
    cursor: pointer;
  }
  &__need-help_menu {
    top: 62px !important;
    min-width: 300px;
    > ul li {
      flex-direction: row-reverse !important;
    }
  }
  &__flex_end {
    display: flex;
    align-items: center;
    column-gap: 32px;
  }
}

.ant-layout {
  background: transparent;
}

.sp-dashboard .layout {
  background-color: $bgColor !important;
  
  .app-layout {
    margin-top: 1.2rem;
  }
}

.avatar_text {
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  background-color: #7535fd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & span {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fefefe;
  }
}

.app-layout {
  margin-inline: 11vw;
}

.main__layout {
  background: $white !important;
}

.sp-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent !important;
  z-index: 9000;

  ul {
    padding: 0;
    &:nth-child(1) {
      li {
        float: left;
      }
    }
    &:nth-child(2) {
      li {
        float: right;
      }
    }
    li {
      list-style: none;
      padding: 10px;

      a {
        font-family: $gs-regular;
        font-size: 12px;
        line-height: 144%;
        text-align: right;
        color: #000000;
        // text-decoration: underline !important;
      }
    }
  }
}

.layout__need-help_menu {
  top: 62px !important;
  min-width: 300px;
  > ul li {
    flex-direction: row-reverse !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}


ion-icon[name="close-outline"] {
  font-size: 40px;
  float: right;
  display: none;
}

@media only screen and (max-width: 440px) {
  .layout__need-help_menu {
    min-width: fit-content;
  }
}

@media only screen and (max-width: 767.98px) {
  .app-layout {
    margin-inline: 2vw !important;
  }
}

@media only screen and (max-width: 960px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .hide-side-menu {
    display: none !important;
    // transition: opacity 1s ease-out;
    // opacity: 0;
    // overflow: hidden;
  }
  .dashboard {
    padding: 0 6px !important;

    &__content {
      margin-left: 0px !important;
    }
  }

  .layout {
    &__nav {
      display: none !important;

      &_mobile {
        display: flex;
        justify-content: space-between;
        // padding: 5px 8vw 5px 8vw !important;
        padding: 4px 25px !important;
      }
    }
    &__logo {
      width: 120px !important;
    }
  }
}



@media (min-width: 768px) {
  .sp-dashboard {
    .layout {
      &__nav {
        padding: 0.5rem 4rem;
      }

      &__header {
        margin-bottom: 1rem;
      }
    }

    .app-layout {
      margin-inline: 20vw;
    }
  
  }
}

.layout__nav_center {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}

.custom-btn-link {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border:none;
  padding: 0.2rem 0.5rem;
  background-color: transparent;
  cursor: pointer;
  column-gap: 8px;
  font-weight: 500;
  transition: all 0.3s ease-in;
  color: #000;

  &:hover {
    color: $primaryTextColor;
    font-weight: 600;

    * {
      fill: $primaryTextColor;
    }
  }
}

.custom-btn-link-active {
  color: $primaryTextColor;
  font-weight: 600;

  * {
    fill: $primaryTextColor;
  }
}

