.sp-button {
    &.disabled {
        opacity: 0.5;
    }
    &.full-width {
        width: 100% !important;

        @media (max-width: 500px) {
            width: 190px !important;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            height: 56px !important;

            span {
                line-height: 0px;
            }
        }

        @media (max-width: 400px) {
            width: 150px !important;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            height: 56px !important;
            span {
                line-height: 0px;
            }
        }

       
    }
    &.ant-btn-primary {
        color           : #fff;
        background-color: #7535fd;
        box-shadow      : none;
    }

    &.ant-btn-primary:not(:disabled):hover {
        color           : #fff;
        background-color: #9665FD;
    }

    span {
        color: #fff;
        font-family: "General Sans";
        font-size: 16px;
        font-weight: 500;
        line-height: 21.6px;
    }

    &.ant-btn.ant-btn-lg {
        height : 48px;
        padding-inline-start: 33px;
        padding-inline-end: 33px;
    }

    &.ant-btn.ant-btn-round {
        border-radius: 26px !important;
    }

    &.ant-btn-outline {
        border: 2px solid #7535fd;

        span {
            color: #7535fd;
        }
    }

    &.ant-btn-default {
        background-color: #ffffff !important;
        border-color: transparent !important; // Fixed "none" to "transparent"
        box-shadow: none !important; // Changed "0" to "none" for clarity
    
        span {
            color: #7535fd;
        }

        &:hover {
            background-color: #7535fd !important;
            span {
                color: #fff;
            }
        }

        &:first-child {
             background-color: #7535fd !important;
            span {
                color: #fff;
            }
        }
    }

    
}