.payment {
    &__wrapper {
        max-width: 435px;
        margin: 1.5rem auto;
    }

    &__card {
        background-color: rgba(234, 241, 255, 1);
        padding: 25.58px 35.08px;
        border-radius: 8px;
    }
}

