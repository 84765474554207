
.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  @media (max-width: 500px) {
    flex-wrap: wrap;
    & > *:not(:last-child) {
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }
}

