$bgColor: #f9f9f9;
$authBgColor: #e5e5e5;
$lceeBlack: #121020;
$textColor: #2c264b;
$primaryTextColor: #7535fd;
$primaryHue: #7535fd20;
$white: #fff;
$lceeGreen: #318d1f;
$error: #f04438;

$borderRadius: 10px;

// fonts
$cg-regular: "Cabinet Grotesk";
$cg-medium: "Cabinet Grotesk Medium";
$cg-bold: "Cabinet Grotesk Bold";
$gs-regular: "General Sans";
$gs-medium: "General Sans Medium";
$gs-semi-bold: "General Sans Semi Bold";
$gs-bold: "General Sans Bold";
