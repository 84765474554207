.modal {
  &__wrapper {
    padding: 2em;
  }
}

@media only screen and (max-width: $small-screen) {
  .modal {
    &__wrapper {
      padding: 0 !important;
    }
  }
}
