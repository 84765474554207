.services {
  &__wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    row-gap: 4rem;
  }
  &__inner {
    height: 56px;
    text-align: center;

    & span {
      font-size: 11px;
      font-family: $gs-medium;
    }
  }
  &__card {
    @include hoverZoomInOut();
    // filter: drop-shadow(0px 8px 20px rgba(177, 177, 177, 0.1));

    &_selected {
      border: 1px $primaryTextColor solid;
      opacity: 1;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      background-color: #ffff;
    }
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    border-radius: 8px;
    filter: drop-shadow(0px 8px 20px rgba(177, 177, 177, 0.1));
    // opacity: 0.6;
  }
  &__img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
  }
  &__form {
    margin-top: 50px;
    &-minmax {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 382px;
      width: 382px;
      margin-top: 8px;
      p {
        font-family: $gs-medium;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 15.84px;
        color: #898989;
        margin: 0;
        span {
          font-family: $gs-medium;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 15.84px;
          color: #898989;
          margin: 0;
          color: #318d1f;
        }
      }
    }
  }
  &__button {
    max-width: 176px;
    width: 100%;
    margin-top: 30px;
  }
  &__input {
    max-width: 380px;
  }
  &__dropdown {
    max-width: 380px !important;
  }
  &__transactions_container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px;
    border: none !important;
    background: $white;
    border-radius: 12px !important;
    @media only screen and (max-width: 450px) {
      padding: 24px 18px !important;
      //
    }
    .filter-container {
      width: 160px;
      max-width: 160px;
      display: flex;
      flex-direction: column;

      gap: 16px;
      &_label {
        font-family: $gs-regular;
        font-size: 11px;
        color: #000000;
        width: fit-content;
      }
      &_button {
        font-family: $gs-regular;
        font-size: 11px;
        color: $lceeBlack;
        display: flex;
        padding: 0 16px;
        height: 36px;
        max-height: 36px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 26px;
        border: 1px solid $primaryTextColor;
        background: #f9f6ff;
        cursor: pointer;
        svg {
          width: 9px !important;
          height: 9px !important;
        }
      }

      @media (max-width: 600px) {
        width: 100%;
        max-width: 100%;
      }
    }

    h3 {
      font-family: $cg-bold;
      font-size: 22px;
      line-height: 27px;
      color: #000000;
      margin: 0;
      text-transform: capitalize;
    }

    .transactions_card {
      display: flex;
      flex-direction: column;

      &-inner {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 8px;
        @media only screen and (max-width: 450px) {
          padding: 16px 0 !important;
        }

        &.with-border-top {
          border-top: 0.5px solid #f0f0f0;
        }

        .transaction-label {
          display: flex;
          align-items: center;
          gap: 16px;
          &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #eae9ed;
          }
          &-amount {
            display: flex;
            flex-direction: column;
            .amount {
              font-family: $gs-semi-bold;
              font-style: normal;
              font-size: 14px;
              line-height: 144%;
              color: #161616;
              margin: 0;
            }
            span {
              position: relative;
              display: flex;
              align-items: center;
              gap: 4px;
              p {
                font-family: $gs-regular;
                font-size: 10px;
                line-height: 14px;
                color: #696969;
                margin: 0;
                @media only screen and (max-width: 345px) {
                  &:first-child {
                    max-width: 40px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                &:nth-child(2) {
                  margin-top: -5px;
                }
                &.payment-method {
                  text-transform: capitalize;
                }
              }
            }
          }
        }

        .transaction-status {
          display: flex;
          align-items: center;
          gap: 16px;
          @media only screen and (max-width: 450px) {
            gap: 4px !important;
          }
          .status {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $gs-medium;
            font-size: 9px;
            line-height: 12px;
            text-align: center;
            padding: 0 16px;
            text-align: center;
            text-transform: capitalize;
            width: fit-content;
            border-radius: 26px;
            height: 24px;
            max-height: 24px !important;

            @media only screen and (max-width: 450px) {
              padding: 0 8px !important;
            }
            
            &.successful, &.success {
              color: #318d1f !important;
              background-color: rgba(49, 141, 31, 0.1) !important;
            }
            &.failed, &.canceled, &.refund, &.refunded {
              color: #F04438;
              background-color: rgba(240, 68, 56, 0.1);
            }
            &.pending, &.initiated {
              color: #F79009;
              background-color: #FEF4E6;
            }
          }
          span {
            &:last-child {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              cursor: pointer;
              svg {
                max-width: 12.92px !important;
                max-height: 12.92px !important;
                transform: rotate(270deg);
              }
            }
          }
        }
      }
      label {
        font-family: $gs-regular;
        font-size: 10px;
        line-height: 14px;
        color: #898989;
        width: fit-content;
        text-transform: uppercase;
      }
    }
  }
}

.loadmore-btn {
  width: 202px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 364px;
    max-width: 364px;
  }
  h3 {
    font-family: $cg-bold;
    font-size: 22px;
    line-height: 27.28px;
    color: $lceeBlack;
    text-align: center;
    margin: 0;
  }
  p {
    font-family: $gs-regular;
    font-style: normal;
    font-size: 14px;
    line-height: 20.16px;
    color: $textColor;
    text-align: center;
    margin: 0;
  }
}

// @media only screen and (max-width: $small-screen) {
//   .services {
//     // &__img {
//     //   // width: auto !important;
//     //   // max-width: 78px !important;
//     // }
//   }
// }

@media (max-width: 483px) {
  .services {
    &__form {
      &-minmax {
        width: 100%;
        padding: 0 4px;
        gap: 4px;
        flex-wrap: wrap;
      }
    }
  }
}

@media (min-width: 320px) {
  .services {
    &__wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (min-width: 350px) {
  .services {
    &__wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (min-width: 600px) {
  .services {
    &__wrapper {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

/* Screen larger than 600px? 2 column */
@media (min-width: 800px) {
  .services {
    &__wrapper {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 1200px) {
  .services {
    &__wrapper {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}


.chevron-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  .pagination-info {
    font-size: 14px;
    color: #555;
    display: flex;
    column-gap: 16px;
  }

  .chevron-container {
    display: flex;
    justify-content: space-evenly;
    column-gap: 24px;
  }

  .chevron-icon {
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
