.bill-receipt {

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: fit-content;

    @media only screen and (max-width: 768px) {
      > .button {
        width: 100% !important;
        max-height: 42px !important;
        padding: 0 16px !important;
      }
    }
  }

  &__recurring-payment-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: fit-content;

    @media only screen and (max-width: 768px) {
      > .button {
        width: 100% !important;
        max-height: 42px !important;
        padding: 0 16px !important;
      }
    }
  }

  
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0px;
    border-bottom: 1px solid #f5ecec;

    &:last-child {
      border-bottom: none !important;
    }


    // Flex children should be aligned on the same row
    .field-label, .field-value {
      display: inline-flex;
      align-items: center;
    }

    .field-label {
      font-family: $gs-regular;
      color: #000;
      text-align: left;
      flex: 0 1 auto;  // Allow the label to take up its space, but not grow
    }

    .field-value {
      font-family: $gs-medium;
      text-align: right;
      color: #000;
      flex: 1 1 auto;  // Let the value take up available space
    }

    .sub-field-value {
      font-family: $gs-regular;
      font-size: 11px;
      text-align: right;
      color: #ccc;
      text-transform: uppercase;
    }

    .multi-value {
      & > .field-value {
        font-family: $gs-medium;
        text-align: right;
        color: #121020;
      }

      & > .sub-field-value {
        font-family: $gs-regular;
        font-size: 11px;
        color: #605c78;
      }
    }
  }

  // Print-specific styles
  @media print {
    .bill-receipt__row {
      display: flex !important;
      flex-direction: row !important; // Ensure it's aligned in a row
      justify-content: space-between !important;
      border-bottom: 1px solid #F5F5F5;
      padding: 16px 0px;
      &:last-child {
        border-bottom: none !important;
      }

        // Flex children should be aligned on the same row
      .field-label, .field-value {
        display: inline-flex;
        align-items: center;
      }

      .field-label {
        font-family: $gs-regular;
        color: #000;
        text-align: left;
        flex: 0 1 auto;  // Allow the label to take up its space, but not grow
        font-size: 16px !important;
      }

      .field-value {
        font-family: $gs-medium;
        font-weight: 600;
        text-align: right;
        color: #000;
        flex: 1 1 auto;  // Let the value take up available space
        font-size: 16px !important;
      }

      .sub-field-value {
        font-family: $gs-regular;
        font-size: 14px;
        text-align: right;
        color: #ccc;
        text-transform: uppercase;
      }

      .multi-value {
        & > .field-value {
          font-family: $gs-medium;
          text-align: right;
          color: #121020;
        }

        & > .sub-field-value {
          font-family: $gs-regular;
          font-size: 14px;
          color: #605c78;
        }
      }
    }
    
    .print-receipt-footer {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      padding: 16px;
      background: #f9f9f9;
      border-radius: 8px;
    
      strong {
        display: inline-block !important;
      }
    }
    
  }
}
